<template>
  <div class="app-container">
    <el-form :model="queryParams" ref="queryForm" :inline="true" v-show="showSearch" label-width="68px" class="query-form">
      <el-form-item label="围栏名称" prop="areaname">
        <el-input
          v-model="queryParams.fenceName"
          placeholder="请输入围栏名称"
          clearable
          size="small"
          @keyup.enter.native="handleQuery"
        />
      </el-form-item>
      <el-form-item label="围栏类型" prop="fenceTimingType" style="display:none">
        <el-select disabled v-model="queryParams.fenceTimingType" placeholder="请选择围栏类型" clearable size="small">
          <el-option
            v-for="dict in fenceTypes"
            :key="dict.value"
            :label="dict.label"
            :value="dict.value"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="围栏形状" prop="fenceShape" style="display:none">
        <el-select disabled v-model="queryParams.fenceShape" placeholder="请选择围栏形状" clearable size="small">
          <el-option
            v-for="dict in fenceShapes"
            :key="dict.value"
            :label="dict.label"
            :value="dict.value"
          />
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" size="small" @click="handleQuery">搜索</el-button>
        <el-button icon="el-icon-refresh" size="small" @click="resetQuery">重置</el-button>
      </el-form-item>
    </el-form>

    <!-- <el-row :gutter="10" class="mb8">
      <el-col :span="1.5">
        <el-button
          type="primary"
          plain
          icon="el-icon-plus"
          size="small"
          @click="handleAdd"
        >新增
        </el-button>
      </el-col>
      <el-col :span="1.5">
        <el-button
          type="danger"
          plain
          icon="el-icon-delete"
          size="small"
          :disabled="multiple"
          @click="handleDelete"
        >停用
        </el-button>
      </el-col>
      <el-col :span="1.5">
        <el-button
          type="warning"
          plain
          icon="el-icon-download"
          size="small"
          :loading="exportLoading"
          @click="handleExport"
        >导出
        </el-button>
      </el-col>
    </el-row> -->

    <el-table border stripe v-loading="loading" :data="mapfenceList" @selection-change="handleSelectionChange">

      <el-table-column label="序号" fixed align="center" type="index"/>
      <!-- <el-table-column label="归属用户" align="center" prop="proxyName"/> -->
      <el-table-column label="围栏名称" align="center" prop="fenceName"/>
      <!-- <el-table-column label="围栏坐标" align="center" prop="lonlats" :show-overflow-tooltip="true"/> -->
      <el-table-column label="围栏形状" align="center" prop="fenceShape">
        <template slot-scope="scope">
          <dict-tag :options="fenceShapes" :value="scope.row.fenceShape"/>
        </template>
      </el-table-column>
      <el-table-column label="围栏类型" align="center" prop="fenceTimingType">
        <template slot-scope="scope">
          <dict-tag :options="fenceTypes" :value="scope.row.fenceTimingType"/>
        </template>
      </el-table-column>
      <!-- <el-table-column label="围栏地址" align="center" prop="address"/> -->
      <el-table-column label="操作" fixed width="150" align="center" class-name="small-padding fixed-width">
        <template slot-scope="scope">
          <el-button
            size="small"
            type="text"
            icon="el-icon-edit"
            @click="handleShow(scope.row)"
          >修改
          </el-button>
<!--          <el-button-->
<!--            size="small"-->
<!--            type="text"-->
<!--            icon="el-icon-edit"-->
<!--            @click="handleUpdate(scope.row)"-->
<!--          >修改-->
<!--          </el-button>-->
          <el-button
            size="small"
            type="text"
            icon="el-icon-delete"
            @click="handleDelete(scope.row)"
          >停用
          </el-button>
        </template>
      </el-table-column>
    </el-table>

    <pagination
      v-show="total>=0"
      :total="total"
      :page.sync="queryParams.pageNum"
      :limit.sync="queryParams.pageSize"
      @pagination="getList"
    >
      <template v-slot:end>
        <el-button
          type="primary"
          icon="el-icon-plus"
          size="small"
          @click="handleAdd"
        >新增
        </el-button>
      </template>
    </pagination>

    <el-dialog title="绘制围栏" :visible.sync="open2" width="1200px" :fullscreen="true" class="fence-dialog" append-to-body>
      <map-fence :key="fenceUpdater" @getList="getList" ref="mapfenceinfo"/>
    </el-dialog>
  </div>
</template>

<script>
import {listMapfence, delMapfence, exportMapfence} from "@/api/schedule/mapfence";
import MapFence from "@/views/schedule/mapfence"
import DictTag from "./components/dictTag.vue"
import Pagination from "./components/pagination.vue"

export default {
  name: "MapFenceList",
  components: {MapFence, DictTag, Pagination},
  props: {
    name: String,
  },
  data() {
    return {
      isAll: false,
      map:null,
      BMap:null,
      circleShow: false,
      circlePath: {},
      polygonShow: false,
      polygonPath: [],
      center: {lng: 116.93761, lat: 40.059866}, // 中心点坐标
      zoom: 11, // 缩放级别
      // 遮罩层
      loading: true,
      // 导出遮罩层
      exportLoading: false,
      // 选中数组
      ids: [],
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      // 显示搜索条件
      showSearch: true,
      // 总条数
      total: 0,
      // 电子围栏表表格数据
      mapfenceList: [],
      // 弹出层标题
      title: "",
      // 是否显示弹出层
      open: false,
      open1: false,
      open2: false,
      fenceUpdater: 1,
      // 查询参数
      queryParams: {
        pageNum: 1,
        pageSize: 10,
        fenceName: null,
        // lonlats: null,
        // fenceShape: null,
        // fenceTimingType: null
      },
      // 表单参数
      form: {},
      // 表单校验
      rules: {},
      fenceShapes: [
        { label: '圆形', value: 1, type: 'success' },
        { label: '多边形', value: 2, type: 'primary' }
      ],
      fenceTypes: [
        { label: '进入计时', value: 1, type: 'primary' },
        { label: '离开计时', value: 2, type: 'warning' }
      ],
    };
  },
  created() {
    if (this.name) {
      this.queryParams.fenceName = this.name;
    }
    this.getList();
  },
  methods: {
    /** 查询电子围栏表列表 */
    getList() {
      this.loading = true;
      let form = Object.fromEntries(
        Object.entries(this.queryParams).map(
          ([k, v]) => [k, v === '' ? null : v]
        )
      )
      listMapfence(form).then(response => {
        this.mapfenceList = response.data.list;
        this.total = response.data.count;
      }).finally(()=> {
        this.loading = false;
      });
      this.open2 = false;
    },
    // 表单重置
    reset() {
      this.form = {
        id: null,
        areaname: null,
        lonlats: null,
        addTime: null,
        type: null
      };
      this.$refs["form"].resetFields();
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParams.pageNum = 1;
      this.getList();
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.$refs["queryForm"].resetFields();
      this.handleQuery();
    },
    // 多选框选中数据
    handleSelectionChange(selection) {
      this.ids = selection.map(item => item.id)
      this.single = selection.length !== 1
      this.multiple = !selection.length
    },
    /** 新增按钮操作 */
    handleAdd() {
      this.open2 = true;
      this.$nextTick(()=>{
        this.$refs.mapfenceinfo.clearForm();
        this.fenceUpdater ++;
      });
    },
    /** 停用按钮操作 */
    handleDelete(row) {
      const ids = row.id || this.ids;
      this.$confirm('是否确认停用围栏「' + ids + '」？').then(function () {
        return delMapfence(ids);
      }).then(() => {
        this.getList();
        this.$message.success("停用成功");
      }).catch(() => {
      });
    },
    /** 导出按钮操作 */
    handleExport() {
      const queryParams = this.queryParams;
      this.$confirm('是否确认导出所有围栏数据项？').then(() => {
        this.exportLoading = true;
        return exportMapfence(queryParams);
      }).then(response => {
        this.$download.name(response.msg);
        this.exportLoading = false;
      }).catch(() => {
      });
    },
    handler({BMap, map}) {
      console.log(BMap, map);
      this.BMap = BMap;
      this.map = map;
    },
    handleShow(row) {
      this.open2 = true;
      this.$nextTick(()=>{
        this.$refs.mapfenceinfo.loadHurdle(row);
      })
    }
  }
};
</script>
<style lang="scss">
.fence-dialog {
  .el-dialog__body {
    padding: 0;
  }
}
</style>
